import { useEffect, useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import ExternalLink from "../../../../src/components/ExternalLink";
import { StarGraphic } from "../../../../src/components/content/StarGraphic";
import { ProgressIndicator } from "../../../../src/components/content/ProgressIndicator";
import { scrollPosition } from "../../../../src/utils/events";
import { useEvents } from "../../../../src/hooks/use-events";
import { usePrevious } from "../../../../src/hooks/use-previous";
import puzzle from "../../../../content/assets/puzzle.png";
import * as React from 'react';
export default {
  useEffect,
  useRef,
  useState,
  useCallback,
  styled,
  motion,
  AnimatePresence,
  ExternalLink,
  StarGraphic,
  ProgressIndicator,
  scrollPosition,
  useEvents,
  usePrevious,
  puzzle,
  React
};