import ExternalLink from "../../../../src/components/ExternalLink";
import InlineImages from "../../../../src/components/InlineImages";
import appleTerm from "../../../../content/assets/apple-terminal.png";
import iterm2 from "../../../../content/assets/iterm-2.png";
import winTerm from "../../../../content/assets/windows-terminal.png";
import prompts from "../../../../content/assets/prompts.png";
import * as React from 'react';
export default {
  ExternalLink,
  InlineImages,
  appleTerm,
  iterm2,
  winTerm,
  prompts,
  React
};