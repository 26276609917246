import React, { FC } from 'react';
import { motion } from 'framer-motion';

export const StarGraphic: FC<{
  length: number;
  offset: number;
  duration: number;
}> = ({ length = 1, offset = 0, duration = 4 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      viewBox='0 0 254 134'
      fill='none'
    >
      <g clipPath='url(#clip0)'>
        <motion.path
          initial={{ pathLength: 0, pathOffset: 0 }}
          animate={{ pathLength: length, pathOffset: offset }}
          transition={{ duration }}
          d='M1.99998 130.677C3.01819 126.877 4.33577 123.273 6.18517 119.786C10.8962 110.902 15.252 101.646 21.3096 93.5488C26.5634 86.5262 33.5821 80.2018 41.3649 76.1412C46.1059 73.6676 55.8037 69.7684 60.5093 74.6547C62.5277 76.7506 62.9372 79.7386 63.6327 82.4363C64.7526 86.7796 64.4768 90.8179 63.8685 95.2158C62.7681 103.171 60.2036 111.672 55.183 118.087C53.9574 119.652 52.1209 121.9 50.1864 122.637C48.5556 123.257 47.3133 121.41 46.7991 120.154C44.1509 113.686 46.5782 105.568 49.0829 99.3484C52.4114 91.0828 58.0401 82.2156 63.898 75.4923C68.6894 69.993 74.7333 63.7098 81.7452 61.2469C85.622 59.8851 90.0352 58.7 90.337 64.206C90.6554 70.0123 88.7485 76.0633 89.6839 81.7916C90.5988 87.3943 93.5861 93.8207 98.4553 96.952C104.894 101.093 111.099 97.2037 116.792 93.4894C125.9 87.5471 134.065 79.2702 141.386 71.3151C144.898 67.4983 150.549 59.4373 150.549 59.4373C150.549 59.4373 156.996 49.7766 159.468 44.7214C160.837 41.9218 165.12 33.0013 161.967 30.1408C158.529 27.0215 152.598 35.5903 151.277 37.5526C146.36 44.8571 141.758 53.0345 142.029 62.173C142.223 68.7337 143.356 78.0172 149.544 81.7596C156.653 86.0594 165.138 80.9215 170.936 76.6992C179.737 70.2905 187.86 63.7756 194.596 55.1193C208.326 37.4766 221.404 19.1075 237.149 3.2213C239.088 1.26555 238.347 3.56836 237.937 5.0981C237.13 8.10968 236.219 11.1132 235.733 14.1974C235.083 18.3271 235.604 22.3379 235.796 26.4847C235.973 30.3036 235.913 34.0011 235.93 37.8058C235.94 40.1152 235.859 40.4049 235.354 38.027C234.901 35.8901 234.69 33.5575 233.858 31.5261C231.028 24.6087 224.925 22.3284 218.201 20.5266C216.083 19.9593 213.422 19.6659 211.47 18.6763C210.914 18.3943 214.783 17.4806 215.301 17.4269C223.279 16.599 231.228 15.9081 239.25 15.6089C242.511 15.4872 245.729 15.018 248.982 14.8381C249.496 14.8096 253.266 13.8785 251.143 15.4406C248.418 17.4456 245.083 18.9357 242.098 20.4779C234.664 24.3179 227.151 28.0707 219.927 32.2977C213.787 35.8898 208.595 40.2322 203.224 44.8088'
          stroke='url(#paint0_linear)'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='6.94408'
          y1='131.733'
          x2='252.253'
          y2='12.1491'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F8D351' />
          <stop offset='0.290178' stopColor='#E99D7C' />
          <stop offset='0.633928' stopColor='#FE4646' />
          <stop offset='1' stopColor='#923FFC' />
        </linearGradient>
        <clipPath id='clip0'>
          <rect width='254' height='134' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
